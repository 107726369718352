import React, { useState, useEffect } from 'react'
import BackBtn from './BackBtn';

function PrintPage(props) {

    const [show, setShow] = useState(false)

    const combinedValues = props.finalValues.map((finalValue, i) => {
        // Get the subValues for this index, or an empty array if none exist
        const subValue = props.subValues[i] || []
        
        // Join the finalValue with the subValues, separated by ' / '
        return [finalValue, ...subValue].join(' / ')
    })

    const rows = new Array(5).fill(null);

    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 500)
    }, [])

    return (
        <div className='print-page'>
            <h1 className='print-page-h1'>Core Values Exercise Results</h1>
            {show ? <BackBtn setProgress={props.setProgress} /> : null}
            <table className='table'>
                <thead>
                    <tr>
                        <th>Value String</th>
                        <th>
                            <div>
                                Description
                            </div>
                            <div><small>(What it means to me & how it shows up in my life)</small></div>
                        </th>
                        <th>
                            <div>
                                Satisfaction Score
                            </div>
                            <div><small>(1-10, where 10 is highest)</small></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((_, i) => (
                        <tr key={i}>
                            <td>{combinedValues[i]}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

// in react js, can you make me a table with 3 columns and 6 rows, where the top row is the column headers. The first column 

export default PrintPage