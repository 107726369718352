import './../App.css';

function NavBar(props) {

    return (
        <div className='navbar'>
            <div style={{ display: 'flex', width: '200px', justifyContent: 'left' }}>
                <a href='https://www.coachwithchanda.com/' style={{ lineHeight: 1, margin: 0, color: 'white', fontWeight: 'bold', fontSize: '18px', cursor: 'pointer', textDecoration: 'none' }}>
                    COACH WITH CHANDA
                </a>
            </div>
            <div className='progress-bar'>
                <div className='progress-btn' onClick={() => props.setProgress(0)}
                    style={{ backgroundColor: props.progress === 0 ? 'white' : 'black', cursor: 'pointer' }} 
                />
                <div className='progress-btn' onClick={props.check1 ? () => props.setProgress(1) : null}
                    style={{ backgroundColor: props.progress === 1 ? 'white' : 'black', cursor: props.check1 ? 'pointer' : 'default' }} 
                />
                <div className='progress-btn' onClick={props.check2 ? () => props.setProgress(2) : null}
                    style={{ backgroundColor: props.progress === 2 ? 'white' : 'black', cursor: props.check2 ? 'pointer' : 'default' }} 
                />
                <div className='progress-btn' onClick={props.check3 ? () => props.setProgress(3) : null}
                    style={{ backgroundColor: props.progress === 3 ? 'white' : 'black', cursor: props.check3 ? 'pointer' : 'default' }} 
                />
                <div className='progress-btn' onClick={props.check4 ? () => props.setProgress(4) : null}
                    style={{ backgroundColor: props.progress === 4 || props.progress === 5 ? 'white' : 'black', cursor: props.check4 ? 'pointer' : 'default' }} 
                />
            </div>
            <div style={{ display: 'flex', width: '200px', justifyContent: 'end' }}>
                <p onClick={() => window.location.reload()} style={{ margin: 0, color: 'white', fontSize: '16px', cursor: 'pointer' }}>
                    Start Over
                </p>
            </div>
        </div>
    )
}

export default NavBar