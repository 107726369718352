import { useState } from 'react'

import FinalValueCard from './FinalValueCard'
import FinalSubValueCard from './FinalSubValueCard'
import PrintBtn from './PrintBtn'
import PrintPage from './PrintPage'

function Final(props) {

    const [discarded, setDiscarded] = useState(props.fullValuesList.filter(value => !props.finalValuesList.includes(value)))

    const [subValues, setSubValues] = useState([[], [], [], [], []])

    const handleOnDrop = (e) => {
        const value = e.dataTransfer.getData("item")
        const indexInSubValuesArr = e.dataTransfer.getData("indexInSubValuesArr")
        
        // if moving from top to bottom
        if (indexInSubValuesArr !== undefined && indexInSubValuesArr !== "") {
            let leavingArr = subValues[indexInSubValuesArr]
            let fixedLeavingArr = leavingArr.filter(item => item !== value)
            let temp = [...subValues]
            temp[indexInSubValuesArr] = fixedLeavingArr
            setSubValues(temp)
            // add value back into discarded area
            setDiscarded([...discarded, value])
        }

    }
    
    const handleDragOver = (e) => {
        e.preventDefault()
    }

    return (
        <div className='final-container'>
            {props.progress === 4 ?
                <>
                    <PrintBtn setProgress={props.setProgress} />
                    <div className='final-subcontainer'>
                        {props.finalValuesList.map((value, index) => (
                            <FinalValueCard key={index} value={value} subValues={subValues} setSubValues={setSubValues} index={index} discarded={discarded} setDiscarded={setDiscarded} />
                        ))}
                    </div>
                    <div className='final-lower-container' onDrop={handleOnDrop} onDragOver={handleDragOver}>
                        <div className='pile-subcontainer'>
                            <div className='pile-values-container'>
                                {discarded.map((value, index) => (
                                    <FinalSubValueCard key={index} value={value} />
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            :
                <PrintPage finalValues={props.finalValuesList} subValues={subValues} progress={props.progress} setProgress={props.setProgress} />
            }
        </div>
    )
}

export default Final