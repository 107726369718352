import ValueCard from "./ValueCard"
import { FaArrowRight } from "react-icons/fa6";

function RightPile(props) {

  const handleOnDrop = (e) => {
    const value = e.dataTransfer.getData("item")
    const from = e.dataTransfer.getData("from")

    // if subvalue already inside
    if (props.nextOptions.includes(value)) {
      return
    }

    // add to new area
    let arr = [...props.nextOptions, value]
    // remove from old area (if in old area)
    if (from === "deck") {
      let oldArr = [...props.currOptions]
      let fixedDeckArr = oldArr.filter(item => item !== value)
      props.setCurrOptions(fixedDeckArr)
      props.setNextOptions(arr)
    }
    // remove from other pile (if in old area)
    else if (from === "left") {
      let currDiscardedOptions = [...props.discardedOptions]
      let fixedDiscardedOptions = currDiscardedOptions.filter(item => item !== value)
      props.setDiscardedOptions(fixedDiscardedOptions)
      props.setNextOptions(arr)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  return (
    <div className='pile-container' style={{ position: 'relative' }}>
        {props.disabled || (props.progress === 0 && props.nextOptions.length >= 20 && props.currOptions.length === 0) ?
          <div className='next-page-btn' 
            onClick={() => props.setProgress(props.progress + 1)}
            style={{ position: 'absolute' }}
          >
            <p style={{ fontWeight: 'bold' }}>Next</p>
            <FaArrowRight />
          </div>
        :
          null
        }
        <div style={{ width: '100%', display: 'flex', justifyContent: 'left'}}>
          <p className='pile-label'>
            {props.label}
          </p>
        </div>
        <div className='pile-subcontainer' onDrop={handleOnDrop} onDragOver={handleDragOver} >
          <div className='pile-values-container'>
            {props.nextOptions.map((value, index) => (
              <ValueCard key={index} value={value} progress={props.progress} pile={'right'}
                discarded={props.discardedOptions} setDiscarded={props.setDiscardedOptions} 
                currOptions={props.currOptions} 
                nextOptions={props.nextOptions} setNextOptions={props.setNextOptions} 
              />
            ))}
          </div>
        </div>
    </div>
  )
}

export default RightPile