import React from 'react'

function PrintBtn(props) {

    const handleClick = () => {
        props.setProgress(5)
        setTimeout(() => {
            window.print()
        }, 10)
    }

    return (
        <div className='next-page-btn' onClick={() => handleClick()}
            style={{ marginBottom: '20px' }}
        >
            <p style={{ fontWeight: 'bold' }} >Print</p>
        </div>
    )
}

export default PrintBtn