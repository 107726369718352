

function DeckValueCard(props) {

    const handleDiscard = () => {
        props.setDiscarded([...props.discarded, props.value])
        props.setCurrOptions(props.currOptions.slice(0, -1))
    }

    const handleSave = () => {
        props.setNextOptions([...props.nextOptions, props.value])
        props.setCurrOptions(props.currOptions.slice(0, -1))
    }

    const handleOnDrag = (e, value) => {
        e.dataTransfer.setData("item", value)
        e.dataTransfer.setData("from", 'deck')
    }

    return (
        <div style={{ position: 'relative', marginLeft: props.currOptions.length / 3 }}>
            <div className='value-card-container'
                draggable
                onDragStart={(e) => handleOnDrag(e, props.value)}
                style={{ cursor: 'pointer' }}
            >
                <div className='card-btn1' onClick={() => handleDiscard()}>

                </div>
                <div className='card-middle'>
                    <p style={{ fontSize: '24px', fontWeight: 'bold', margin: 0, lineHeight: 1 }}>{props.value}</p>
                </div>
                <div className='card-btn2' onClick={() => handleSave()} >

                </div>
            </div>
            {props.currOptions.map((item, index) => (
                <div key={index} className='value-card-container' style={{ position: 'absolute', backgroundColor: '#CDCDCD', right: index / 3, top: index / 3, zIndex: -1 }} >
                </div>
            ))}
        </div>
    )
}

export default DeckValueCard