import React from 'react'

function BackBtn(props) {
    return (
        <div className='next-page-btn' onClick={() => props.setProgress(4)}
            style={{ marginBottom: '20px' }}
        >
            <p style={{ fontWeight: 'bold' }} >Go Back</p>
        </div>
    )
}

export default BackBtn