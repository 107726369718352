import React from 'react'

import {useState, useEffect} from 'react'

import LeftPile from './LeftPile'
import RightPile from './RightPile'
import CardsDeck from './CardsDeck'

function Main0(props) {

    return (
        <div className='app-subcontainer'>
            <LeftPile label={'Discard Pile'} progress={props.progress} 
                disabled={false}
                discardedOptions={props.discardedOptions} setDiscardedOptions={props.setDiscardedOptions} 
                currOptions={props.currOptions} setCurrOptions={props.setCurrOptions}
                nextOptions={props.nextOptions} setNextOptions={props.setNextOptions} 
            />
            <CardsDeck setDiscarded={props.setDiscardedOptions} discarded={props.discardedOptions} 
                currOptions={props.currOptions} setCurrOptions={props.setCurrOptions}
                nextOptions={props.nextOptions} setNextOptions={props.setNextOptions} 
            />
            <RightPile label={'Keep Pile'} progress={props.progress} setProgress={props.setProgress} 
                disabled={false}
                discardedOptions={props.discardedOptions} setDiscardedOptions={props.setDiscardedOptions} 
                currOptions={props.currOptions} setCurrOptions={props.setCurrOptions}
                nextOptions={props.nextOptions} setNextOptions={props.setNextOptions} 
            />
        </div>
    )
}

export default Main0