import ValueCard from "./ValueCard"

function LeftPile(props) {

  const handleOnDrop = (e) => {
    const value = e.dataTransfer.getData("item")
    const from = e.dataTransfer.getData("from")

    // if subvalue already inside
    if (props.discardedOptions.includes(value)) {
      return
    }

    // add to new area
    let arr = [...props.discardedOptions, value]
    // remove from old area (if in old area)
    if (from === "deck") {
      let oldArr = [...props.currOptions]
      let fixedDeckArr = oldArr.filter(item => item !== value)
      props.setCurrOptions(fixedDeckArr)
      props.setDiscardedOptions(arr)
    }
    // remove from other pile (if in old area)
    else if (from === "right") {
      let currNextOptions = [...props.nextOptions]
      let fixedNextOptions = currNextOptions.filter(item => item !== value)
      props.setNextOptions(fixedNextOptions)
      props.setDiscardedOptions(arr)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  return (
    <div className='pile-container'>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'left'}}>
          <p className='pile-label' >
            {props.label}
          </p>
        </div>
        <div className='pile-subcontainer' onDrop={handleOnDrop} onDragOver={handleDragOver}>
          <div className='pile-values-container'>
            {props.discardedOptions.map((value, index) => (
              <ValueCard key={index} value={value} progress={props.progress} pile={'left'}
                discarded={props.discardedOptions} setDiscarded={props.setDiscardedOptions} 
                currOptions={props.currOptions} disabled={props.disabled}
                nextOptions={props.nextOptions} setNextOptions={props.setNextOptions} 
              />
            ))}
          </div>
        </div>
    </div>
  )
}

export default LeftPile