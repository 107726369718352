
import DeckValueCard from "./DeckValueCard"

function CardsDeck(props) {

    return (
        <div className='deck-container' >
            {props.currOptions.length > 0 ?
                <DeckValueCard 
                    value={props.currOptions[props.currOptions.length - 1]} 
                    currOptions={props.currOptions} setCurrOptions={props.setCurrOptions}
                    setDiscarded={props.setDiscarded} discarded={props.discarded} 
                    setNextOptions={props.setNextOptions} nextOptions={props.nextOptions} 
                />
            :
            <div className='deck-subcontainer'>
                
            </div>
            }
            <p style={{ color: 'white', margin: '30px 0 0 0' }}>
                {props.currOptions.length} value{props.currOptions.length === 1 ? '': 's'} remaining
            </p>
        </div>
    )
}

export default CardsDeck