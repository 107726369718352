
import './App.css';

import {useState, useEffect} from 'react'

import Instructions from './components/Instructions';
import NavBar from './components/Navbar';
import Main from './components/Main';
import Main0 from './components/Main0';
import Final from './components/Final';


function App() {

  const [fullValuesList, setFullValuesList] = useState([]);

  const [progress, setProgress] = useState(0)

  // progress 0
  const [currOptions0, setCurrOptions0] = useState(fullValuesList)
  const [discardedOptions0, setDiscardedOptions0] = useState([])
  const [nextOptions0, setNextOptions0] = useState([])

  // progress 1
  const [discardedOptions1, setDiscardedOptions1] = useState([])
  const [nextOptions1, setNextOptions1] = useState([])

  // progress 2
  const [discardedOptions2, setDiscardedOptions2] = useState([])
  const [nextOptions2, setNextOptions2] = useState([])

  // progress 3
  const [discardedOptions3, setDiscardedOptions3] = useState([])
  const [nextOptions3, setNextOptions3] = useState([])

  let component
  if (progress === 0) {
    component = (
      <>
        <Instructions text1={'Sort the cards into two piles'} 
          text2={`A keep pile with at least 20 values that resonate and a discard pile for ones that don't ring true`} 
        />
        <Main0 progress={progress} setProgress={setProgress} 
          currOptions={currOptions0} setCurrOptions={setCurrOptions0}
          discardedOptions={discardedOptions0} setDiscardedOptions={setDiscardedOptions0}
          nextOptions={nextOptions0} setNextOptions={setNextOptions0} 
        />
      </>
    )
  } else if (progress === 1) {
    component = (
      <>
        <Instructions text1={'Discover my values'} 
          text2={`Narrow down your selection to your top 15 values`} 
        />
        <Main progress={progress} setProgress={setProgress} 
          currOptions={nextOptions0} 
          discardedOptions={discardedOptions1} setDiscardedOptions={setDiscardedOptions1}
          nextOptions={nextOptions1} setNextOptions={setNextOptions1}  
        />
      </>
    )
  } else if (progress === 2) {
    component = (
      <>
        <Instructions text1={'Discover my values'} 
          text2={`Further narrow down your selection to 10 values`} 
        />
        <Main progress={progress} setProgress={setProgress} 
          currOptions={nextOptions1} 
          discardedOptions={discardedOptions2} setDiscardedOptions={setDiscardedOptions2}
          nextOptions={nextOptions2} setNextOptions={setNextOptions2}  
        />
      </>
    )
  } else if (progress === 3) {
    component = (
      <>
        <Instructions text1={'Discover my values'} 
          text2={`Pick your final 5 core values`} 
        />
        <Main progress={progress} setProgress={setProgress} 
          currOptions={nextOptions2} 
          discardedOptions={discardedOptions3} setDiscardedOptions={setDiscardedOptions3}
          nextOptions={nextOptions3} setNextOptions={setNextOptions3}  
        />
      </>
    )
  } else if (progress === 4 || progress === 5) {
    component = (
      <>
        {progress === 4 ?
          <Instructions text1={'Create Value Strings (Optional)'} 
            text2={`Drag up to two aligned values from the discard pile under a core value to create a value string`} 
          />
        :
          null
        }
        <Final fullValuesList={fullValuesList} finalValuesList={nextOptions3} progress={progress} setProgress={setProgress} />
      </>
    )
  }

  useEffect(() => {
    fetch('words.txt')
      .then(response => response.text())
      .then(data => {
        // The data is the file contents. You can split it into an array.
        const values = data.split('\n') // Split by newline
        setCurrOptions0(values)
        setFullValuesList(values)
      })
  }, [])


  return (
    <div className="App">
      <div className='app-container'>
        <NavBar progress={progress} setProgress={setProgress} 
          check1={nextOptions0.length >= 20 && currOptions0.length === 0} 
          check2={nextOptions1.length === 15} 
          check3={nextOptions2.length === 10} 
          check4={nextOptions3.length === 5}
        />
        {component}
      </div>
    </div>
  );
}

export default App;
