import React from 'react'

import {useState, useEffect} from 'react'

import LeftPile from './LeftPile'
import RightPile from './RightPile'

function Main(props) {

    const [disabled, setDisabled] = useState(false)

    // disable card buttons if goal met
    useEffect(() => {
        if (props.progress === 1 && props.nextOptions.length === 15) {
            setDisabled(true)
        } else if (props.progress === 2 && props.nextOptions.length === 10) {
            setDisabled(true)
        } else if (props.progress === 3 && props.nextOptions.length === 5) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [props.nextOptions])

    
    // if went back and made changes to currOptions (in prev step)
    useEffect(() => {
        // checks if any values were removed from current keep pile (by being removed in earlier step)
        let result = props.nextOptions.filter(item => !props.currOptions.includes(item))
        props.setNextOptions(props.nextOptions.filter(item => !result.includes(item)))
        // check if any values were removed from current discard pile (by being removed in earlier step)
        let result2 = props.discardedOptions.filter(item => !props.currOptions.includes(item))
        let newDiscardedOptions = props.discardedOptions.filter(item => !result2.includes(item))
        // check if any values added to old keep pile (currOptions) (by being added in earlier step)
        let result3 = props.currOptions.filter(item => !props.discardedOptions.includes(item) && !props.nextOptions.includes(item))
        props.setDiscardedOptions([...newDiscardedOptions, ...result3])
    }, [props.currOptions])
    

    // on first render only
    useEffect(() => {
        if (props.discardedOptions.length === 0 && props.nextOptions.length === 0) {
            props.setDiscardedOptions(props.currOptions)
        }
    })

    return (
        <div className='app-subcontainer'>
            <LeftPile label={'Discard Pile'} progress={props.progress} 
                disabled={disabled}
                discardedOptions={props.discardedOptions} setDiscardedOptions={props.setDiscardedOptions} 
                currOptions={[]} setCurrOptions={props.setCurrOptions}
                nextOptions={props.nextOptions} setNextOptions={props.setNextOptions} 
            />
            <RightPile label={'Keep Pile'} progress={props.progress} setProgress={props.setProgress} 
                discardedOptions={props.discardedOptions} setDiscardedOptions={props.setDiscardedOptions} 
                disabled={disabled}
                currOptions={[]} setCurrOptions={props.setCurrOptions}
                nextOptions={props.nextOptions} setNextOptions={props.setNextOptions} 
            />
        </div>
    )
}

export default Main