import FinalSubValueCard from './FinalSubValueCard'

function FinalValueCard(props) {

  const handleOnDrop = (e) => {
    const value = e.dataTransfer.getData("item")
    const indexInSubValuesArr = e.dataTransfer.getData("indexInSubValuesArr")

    // if subvalue already inside
    if (props.subValues[props.index].includes(value)) {
      return
    }

    let updatedDiscarded = [...props.discarded]

    if (props.subValues[props.index].length < 2) {
      // add to new area
      let subValuesArr = [...props.subValues[props.index], value]
      let temp = [...props.subValues]
      temp[props.index] = subValuesArr
      // remove from old area (if in old area)
      if (indexInSubValuesArr !== undefined && indexInSubValuesArr !== "") {
        let oldSubValuesArr = [...props.subValues[indexInSubValuesArr]]
        let fixedOldSubValuesArr = oldSubValuesArr.filter(item => item !== value)
        let temp2 = [...temp]
        temp2[indexInSubValuesArr] = fixedOldSubValuesArr
        props.setSubValues(temp2)
      }
      else {
        props.setSubValues(temp)
      }
      // delete from discarded (so cant be reused)
      updatedDiscarded = props.discarded.filter(item => item !== value)
      props.setDiscarded(updatedDiscarded)
    }
    // if has 2 subvalues already 
    else {
      let subValuesArr = [...props.subValues[props.index]]
      let newSubValuesArr = [value, subValuesArr[0]]
      let temp = [...props.subValues]
      temp[props.index] = newSubValuesArr
      // remove from old area (if in old area)
      if (indexInSubValuesArr !== undefined && indexInSubValuesArr !== "") {
        let oldSubValuesArr = [...props.subValues[indexInSubValuesArr]]
        let fixedOldSubValuesArr = oldSubValuesArr.filter(item => item !== value)
        let temp2 = [...temp]
        temp2[indexInSubValuesArr] = fixedOldSubValuesArr
        props.setSubValues(temp2)
      }
      // if from bottom 
      else {
        props.setSubValues(temp)
        // delete from discarded (so cant be reused)
        updatedDiscarded = props.discarded.filter(item => item !== value)
      }
      // add subvalue that was kicked out back to discarded
      props.setDiscarded([...updatedDiscarded, subValuesArr[1]])
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  return (
    <div className='final-card-container' onDrop={handleOnDrop} onDragOver={handleDragOver} >
      <div className='value-card-container2' style={{ minWidth: '100%' }}>
        <p style={{ fontSize: '18px', fontWeight: 'bold', margin: 0, lineHeight: 1 }}>{props.value}</p>
      </div>
      <div className='subvalue-card-container' >
        {props.subValues[props.index].map((value, index) => (
          <FinalSubValueCard key={index} value={value} indexInCoreValue={index} indexInSubValuesArr={props.index} />
        ))}
      </div>
    </div>
  )
}

export default FinalValueCard