

function Instructions(props) {
    return (
        <div className='instructions-container'>
            <div className='instructions-container2'>
                <h1 style={{ color: 'white', margin: '0 0 10px 0' }} >
                    Values Exercise
                </h1>
                <h3 style={{ color: 'white', margin: '0 0 5px 0' }} >
                    {props.text1}:
                </h3>
                <p style={{ color: 'white', margin: '0 0 5px 0' }} >
                    {props.text2}
                </p>
            </div>
        </div>
    )
}

export default Instructions