

function ValueCard(props) {

    const handleClick = () => {
        if (!props.disabled) {
            if (props.progress === 0 && props.discarded.includes(props.value)) {
                props.setNextOptions([...props.nextOptions, props.value])
                props.setDiscarded(props.discarded.filter(item => item !== props.value))
            } else if (props.progress !== 0 & props.discarded.includes(props.value)) {
                props.setNextOptions([...props.nextOptions, props.value])
                props.setDiscarded(props.discarded.filter(item => item !== props.value))
            } else if (props.progress === 0 && props.nextOptions.includes(props.value)) {
                props.setDiscarded([...props.discarded, props.value])
                props.setNextOptions(props.nextOptions.filter(item => item !== props.value))
            } else {
                props.setDiscarded([...props.discarded, props.value])
                props.setNextOptions(props.nextOptions.filter(item => item !== props.value))
            }
        }
    }

    const handleOnDrag = (e, value) => {
        e.dataTransfer.setData("item", value)
        e.dataTransfer.setData("from", props.pile)
    }

    return (
        <div className='value-card-container2' onClick={() => handleClick()} 
            style={{ cursor: props.disabled ? 'default' : 'pointer' }}
            draggable
            onDragStart={(e) => handleOnDrag(e, props.value)}
        >
            <p style={{ fontSize: '16px', fontWeight: 'bold', margin: 0, lineHeight: 1 }}>{props.value}</p>
        </div>
    )
}

export default ValueCard