import React from 'react'

function FinalSubValueCard(props) {

  const handleOnDrag = (e, value) => {
    e.dataTransfer.setData("item", value)
    if (props.indexInSubValuesArr !== undefined && props.indexInSubValuesArr !== "") {
      e.dataTransfer.setData("indexInSubValuesArr", props.indexInSubValuesArr)
    }
  }

  return (
    <div className='value-card-container2'
      style={{ height: '45px', minHeight: '45px', cursor: 'grab', width: '100%' }}
      draggable
      onDragStart={(e) => handleOnDrag(e, props.value)}    
    >
      <p style={{ fontSize: '14px', fontWeight: 'bold', margin: 0, lineHeight: 1 }}>{props.value}</p>
    </div>
  )
}

export default FinalSubValueCard